import React from "react";
import { ColorRing } from "react-loader-spinner";
import styles from "./button.module.scss";
import { motion } from "framer-motion";
const Button = ({
  buttonText,
  icon,
  image,
  iconText,
  imageClassName = "",
  onClick,
  overrideClassName,
  loading,
  disabled,
  onMouseEnter,
  loaderSize = 30,
  ...props
}) => {
  return (
    <motion.button
      style={{
        backgroundColor: disabled ? "gray" : "",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      className={`${styles.button} ${overrideClassName}`}
      onClick={() => !disabled && onClick()}
      onMouseEnter={onMouseEnter}
      {...props}
    >
      {icon && icon}
      {image && <img src={image} alt="icon" className={imageClassName} />}
      {iconText && <span>{iconText}</span>}
      {loading ? (
        <ColorRing
          visible={true}
          height={loaderSize}
          width={loaderSize}
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={[
            "rgb(53,148,180)",
            "white",
            "rgb(53,148,180)",
            "white",
            "rgb(53,148,180)",
          ]}
        />
      ) : (
        <span style={{ marginLeft: 5 }}>{buttonText}</span>
      )}
    </motion.button>
  );
};

export default Button;
