import React from "react";
import styles from "./LiveStream.module.scss"; // Assuming you're using module.scss for styling

const LiveStream = ({ channelId }) => {
  return (
    <div className={styles.videoContainer}>
      <iframe
        className={styles.iframe}
        src={`https://www.youtube.com/embed/live_stream?channel=${channelId}`}
        frameBorder="0"
        allowFullScreen={true}
        title="Live Stream"
      ></iframe>
    </div>
  );
};

export default LiveStream;
