import * as XLSX from "xlsx";
export function exportToExcel(data, fileName = "data.xlsx", totalRow = {}) {
  // Create a new workbook and add a worksheet
  const workbook = XLSX.utils.book_new();

  // Create the worksheet from data
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Calculate the row number for the total (1-based index)
  const lastRowIndex = data.length + 1; // Since `data.length` gives 0-based index

  // Add the "Total" row with index starting from 1
  if (totalRow && Object.keys(totalRow).length > 0) {
    // Convert totalRow into an array to map column positions
    const columns = Object.keys(totalRow);
    columns.forEach((col, i) => {
      // XLSX column names start from A, B, C, and so on
      const cellAddress = XLSX.utils.encode_cell({ r: lastRowIndex, c: i });
      worksheet[cellAddress] = { t: "s", v: totalRow[col] }; // t: type (string), v: value
    });

    // Update the worksheet range to include the new row
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    range.e.r = lastRowIndex; // Extend the range to the last row
    worksheet["!ref"] = XLSX.utils.encode_range(range);
  }

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Donations");

  // Generate Excel file and initiate download
  XLSX.writeFile(workbook, fileName);
}

export const checkPermission = (userPermissions, key, permission) => {
  if (userPermissions[key][permission]) {
    return true;
  }
  return false;
};
