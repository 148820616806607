import React, { useEffect, useState } from "react";
import classes from "./NavBar.module.scss";
import { IoClose, IoMenu } from "react-icons/io5";
import useTheme from "../../utils/useTheme";
import { useLocation, useNavigate } from "react-router-dom";
import { CgMenuRightAlt } from "react-icons/cg";
import { MdLiveTv, MdOutlineNotStarted } from "react-icons/md";
import { getAllConfigs, toggleConfigEntry } from "../../utils/network/Crud";
import Button from "../Button/Button";
const NavBar = () => {
  const navigate = useNavigate();
  const { isDarkMode, userData, updateUserData, configs, setConfigs } =
    useTheme();
  const [activeTab, setActiveTab] = useState("home");
  const [openMenu, setOpenMenu] = useState(false);
  const [channelId, setChannelId] = useState(configs.channelId);
  const tabs = [
    { key: "/", title: "Home" },
    { key: "donations", title: "Donation" },
    { key: "expenses", title: "Expense" },
    { key: "events", title: "Events" },
  ];
  const handleNavigateToPage = (id) => {
    navigate(id);
    setOpenMenu(!openMenu);
  };
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      setActiveTab(location.pathname);
      return;
    }
    setActiveTab(location.pathname.slice(1));
  }, [location, userData]);
  const handleLogout = async () => {
    await localStorage.clear();
    updateUserData({});
    navigate("/");
  };
  const toggleLive = async (state) => {
    await toggleConfigEntry("showLive", state);
    await getConfigData();
  };
  const getConfigData = async () => {
    const data = await getAllConfigs();
    if (data) {
      setConfigs({
        ...configs,
        showLive: data?.data[1]?.showLive,
        channelId: data?.data[0]?.channelId,
      });
    }
  };
  const updateUrl = async () => {
    await toggleConfigEntry("channelId", channelId);
  };
  return (
    <nav
      className={`${!isDarkMode && classes.navLight} ${classes.nav} ${
        openMenu && classes.extraStyle
      }`}
    >
      <div className={classes.navsContainer}>
        <img
          className={classes.logo}
          src={require("../../assets/logo.jpg")}
        ></img>
        <ul className={openMenu ? classes.openMenu : classes.closeMenu}>
          {tabs?.map((elem, index) => {
            return (
              <li
                className={activeTab === elem?.key && classes.activeTab}
                key={index}
                onClick={() => handleNavigateToPage(elem?.key)}
              >
                {elem?.title}
              </li>
            );
          })}
          {userData?.permissions?.addMember?.write && (
            <li
              className={activeTab === "/addMember"}
              onClick={() => handleNavigateToPage("/addMember")}
            >
              Add Member
            </li>
          )}
          {userData?.permissions?.startLive?.write && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "black",
                  fontSize: 13,
                  marginLeft: 25,
                }}
              >
                {!configs?.showLive ? "Start Live" : "Stop Live"}
                <div className={classes.modeBtn}>
                  <div
                    className={`${classes.changeBtn} ${
                      configs?.showLive && classes.toggled
                    }`}
                  >
                    {!configs?.showLive ? (
                      <MdOutlineNotStarted
                        onClick={() => toggleLive(true)}
                        className={classes.icon}
                      />
                    ) : (
                      <MdLiveTv
                        onClick={() => toggleLive(false)}
                        className={classes.icon}
                      />
                    )}
                  </div>
                </div>
              </div>
              {configs?.showLive && (
                <div className={classes.channelIdBox}>
                  <input
                    defaultValue={configs.channelId}
                    value={channelId}
                    onChange={(e) => setChannelId(e.target.value)}
                  />
                  <Button
                    overrideClassName={classes.btn}
                    buttonText={"Update"}
                    onClick={updateUrl}
                  />
                </div>
              )}
            </>
          )}
          <li
            className={activeTab === "login" && classes.activeTab}
            onClick={() => {
              userData?.isLoggedIn
                ? handleLogout()
                : handleNavigateToPage("login");
            }}
          >
            {userData?.isLoggedIn ? "Logout" : "Login"}
          </li>
        </ul>
        {openMenu ? (
          <IoClose
            onClick={() => setOpenMenu(!openMenu)}
            className={`${classes.menu} ${openMenu && classes.extraStyle}`}
          />
        ) : (
          <CgMenuRightAlt
            onClick={() => setOpenMenu(!openMenu)}
            className={`${classes.menu} ${openMenu && classes.extraStyle}`}
          />
        )}
      </div>
    </nav>
  );
};

export default NavBar;
