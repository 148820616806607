import React from "react";
import DonationForm from "./Component/DonationForm";
import BackButton from "../../Components/BackButton";

const AddDonation = (collection) => {
  return (
    <>
      {(collection?.collection === "donation" || collection === "donation") && (
        <BackButton title={"Add Donation"} />
      )}
      <DonationForm collection={collection} />
    </>
  );
};

export default AddDonation;
