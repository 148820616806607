import { useState } from "react";
import axios from "axios";

const useCloudinary = () => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [imageUrl, setImageUrls] = useState([]); // Support for multiple image URLs

  const uploadImage = async (imageFiles) => {
    const isMultiple = Array.isArray(imageFiles) || imageFiles.length > 1;
    const filesArray = isMultiple ? Array.from(imageFiles) : [imageFiles];

    const formDataArray = filesArray.map((imageFile) => {
      const formData = new FormData();
      formData.append("file", imageFile);
      formData.append("upload_preset", "navratri_images"); // Replace with your Cloudinary upload preset
      formData.append("cloud_name", "drnml80um"); // Replace with your Cloudinary cloud name
      return formData;
    });

    try {
      setUploading(true);
      const uploadPromises = formDataArray.map((formData) =>
        axios.post(
          `https://api.cloudinary.com/v1_1/drnml80um/image/upload`,
          formData
        )
      );

      const responses = await Promise.all(uploadPromises);
      const urls = responses.map((response) => response.data.secure_url);
      setImageUrls(urls);
      setUploading(false);

      // Return single URL if one file was uploaded, otherwise return the array of URLs
      return isMultiple ? urls : urls[0];
    } catch (err) {
      setError(err);
      setUploading(false);
    }
  };

  return { uploadImage, uploading, imageUrl, error };
};

export default useCloudinary;
