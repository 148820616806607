import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  createDonationEntry,
  createGalleryEntry,
  updateDonation,
} from "../../../utils/network/Crud";
import styles from "./DonationForm.module.scss";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import useTheme from "../../../utils/useTheme";
import useCloudinary from "../../../utils/useCloudinary";

// Utility function to convert Firestore timestamp to YYYY-MM-DD
const formatDate = (timestamp) => {
  if (timestamp) {
    const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
    return date.toISOString().split("T")[0]; // Format to YYYY-MM-DD
  }
  return "";
};

const DonationForm = ({ collection, isEdit, editData }) => {
  const currentCollection = collection?.collection || collection;
  const [isPending, setIsPending] = useState(true);
  const { uploadImage, uploading } = useCloudinary();
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { globalLoading, toggleGlobalLoading } = useTheme();

  // Initialize form values if editing
  useEffect(() => {
    if (isEdit && editData) {
      setValue("amount", editData.amount);
      if (currentCollection === "donation") {
        setValue("name", editData.name);
        setValue("address", editData.address);
      } else if (currentCollection === "expense") {
        setValue("description", editData.description);
        setValue("spendBy", editData.spendBy);
        setUploadedImageUrls(editData?.images);
      }
      setValue("date", formatDate(editData.date)); // Use utility function to format date
      setIsPending(editData.isPending);
    }
  }, [isEdit, editData, setValue, collection]);

  const onSubmit = async (data) => {
    toggleGlobalLoading(true);
    const entry = {
      date: new Date(data.date), // common field for both donation and expense
    };
    entry.amount = parseFloat(data.amount);

    entry.isPending = isPending; // Pending state only for donations
    // Check if the collection is donation or expense
    if (currentCollection === "donation") {
      // Only for donations
      entry.name = data.name;
      entry.address = data.address;
    } else if (currentCollection === "expense") {
      // Only for expenses
      entry.description = data.description;
      entry.spendBy = data.spendBy;
      entry.images = uploadedImageUrls;
    }

    try {
      if (isEdit && editData?.id) {
        // Update existing entry (donation or expense)
        const result = await updateDonation(collection, entry, editData.id);
        if (result) {
          toast.success(
            `${
              currentCollection === "donation" ? "Donation" : "Expense"
            } updated successfully`,
            { autoClose: 1200 }
          );
        }
      } else {
        // Create new entry (donation or expense)
        const result = await createDonationEntry(collection, entry);
        if (result) {
          toast.success(
            `${
              currentCollection === "donation" ? "Donation" : "Expense"
            } added successfully`,
            { autoClose: 1200 }
          );
        }
      }
      reset(); // Reset the form after successful submission
      navigate(-1);
    } catch (error) {
      console.error(`Error saving ${currentCollection} entry:`, error);
      toast.error(`Error saving ${currentCollection} entry.`);
    } finally {
      toggleGlobalLoading(false);
    }
  };
  //image-upload

  const handleUpload = async (e) => {
    toggleGlobalLoading(true);
    if (Array.from(e.target.files).length === 0) return;

    const uploadPromises = Array.from(e.target.files).map(async (image) => {
      const result = await uploadImage(image);
      return result; // Return the result instead of updating the state inside
    });

    const imageUrls = await Promise.all(uploadPromises);
    if (imageUrls) {
      toggleGlobalLoading(false);
      setUploadedImageUrls(imageUrls);
    }
  };
  return (
    <div className={styles.mainContainer}>
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        {currentCollection === "donation" && (
          <>
            <div className={styles.formGroup}>
              <label htmlFor="name">Name</label>
              <input
                id="name"
                type="text"
                {...register("name", { required: "Name is required" })}
              />
              {errors.name && (
                <p className={styles.errorMessage}>{errors.name.message}</p>
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="address">Address</label>
              <textarea
                id="address"
                {...register("address", { required: "Address is required" })}
              />
              {errors.address && (
                <p className={styles.errorMessage}>{errors.address.message}</p>
              )}
            </div>
          </>
        )}
        <div className={styles.formGroup}>
          <label htmlFor="amount">Amount</label>
          <input
            id="amount"
            type="number"
            {...register("amount", {
              required: "Amount is required",
              min: {
                value: 0.01,
                message: "Amount must be greater than 0",
              },
            })}
            step="0.01"
          />
          {errors.amount && (
            <p className={styles.errorMessage}>{errors.amount.message}</p>
          )}
        </div>
        {currentCollection === "expense" && (
          <>
            <div className={styles.formGroup}>
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                {...register("description", {
                  required: "Description is required",
                })}
              />
              {errors.description && (
                <p className={styles.errorMessage}>
                  {errors.description.message}
                </p>
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="spendBy">Spend By</label>
              <input
                id="spendBy"
                type="text"
                {...register("spendBy", { required: "Spend By is required" })}
              />
              {errors.spendBy && (
                <p className={styles.errorMessage}>{errors.spendBy.message}</p>
              )}
            </div>
            {currentCollection === "expense" && (
              <div className={styles.imageUploadContainer}>
                <h2>Upload Images</h2>
                <input
                  type="file"
                  onChange={handleUpload}
                  multiple
                  className={styles.fileInput}
                />
              </div>
            )}
          </>
        )}

        <div className={styles.formGroup}>
          <label htmlFor="date">Date</label>
          <input
            id="date"
            type="date"
            {...register("date", { required: "Date is required" })}
          />
          {errors.date && (
            <p className={styles.errorMessage}>{errors.date.message}</p>
          )}
        </div>
        <div className={styles.formGroup}>
          <label className={styles.radioLabel}>
            <input
              type="radio"
              name="isPending"
              value="true"
              checked={isPending}
              onChange={() => setIsPending(true)}
            />
            <span className={styles.radioCustom}></span>
            {currentCollection === "donation" ? "Pending" : "Payment Pending"}
          </label>
          <label className={styles.radioLabel}>
            <input
              type="radio"
              name="isPending"
              value="false"
              checked={!isPending}
              onChange={() => setIsPending(false)}
            />
            <span className={styles.radioCustom}></span>
            {currentCollection === "donation"
              ? "Completed"
              : "Payment Completed"}
          </label>
        </div>
        <Button
          loading={globalLoading}
          overrideClassName={styles.submitButton}
          type="submit"
          onClick={handleSubmit}
          buttonText={
            isEdit
              ? `Update ${
                  currentCollection === "donation" ? "Donation" : "Expense"
                }`
              : `Add ${
                  currentCollection === "donation" ? "Donation" : "Expense"
                }`
          }
        ></Button>
      </form>
    </div>
  );
};

export default DonationForm;
