import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Auth_Users } from "../../Auth";
import useTheme from "../../utils/useTheme";
import "./Login.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { updateUserData, userData } = useTheme();
  // Function to handle form submission
  const onSubmit = async (data) => {
    const user = Auth_Users.find(
      (user) => user.email === data.email && user.password === data.password
    );
    const finalPayload = { ...user, isLoggedIn: true };
    await localStorage.setItem("Db456", JSON.stringify(finalPayload));
    if (user) {
      updateUserData(finalPayload);
      toast.success("Login SuccessFull");
      navigate("/");
    } else {
      toast.warn("Invalid email or password");
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>Login</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              {...register("email", { required: "Email is required" })}
              placeholder="Enter your email"
            />
            {errors.email && (
              <span className="error">{errors.email.message}</span>
            )}
          </div>

          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              {...register("password", { required: "Password is required" })}
              placeholder="Enter your password"
            />
            {errors.password && (
              <span className="error">{errors.password.message}</span>
            )}
          </div>

          <button type="submit" className="login-btn">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
