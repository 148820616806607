import React from "react";
import DonationForm from "./Component/DonationForm";
import { useLocation } from "react-router-dom";
import BackButton from "../../Components/BackButton";

const EditDonation = ({ collection }) => {
  const { state } = useLocation();
  return (
    <>
      {" "}
      {collection?.collection === "donation" ||
        (collection === "donation" && <BackButton title={"Edit Donation"} />)}
      <DonationForm collection={collection} isEdit={true} editData={state} />
    </>
  );
};

export default EditDonation;
