import React from "react";
import Lottie from "react-lottie";
import animationData from "./LoaderAnim.json"; // Replace with the path to your Lottie JSON file
import styles from "./Loader.module.scss"; // Optional: import custom styles

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true, // Change to false if you want to control playback manually
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice", // Ensures proper scaling
    },
  };

  return (
    <div className={styles.lottieContainer}>
      <Lottie options={defaultOptions} height={250} width={250} />
    </div>
  );
};

export default Loader;
