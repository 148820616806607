import React, { useState } from "react";
import styles from "./UploadImage.module.scss";
import useTheme from "../../utils/useTheme";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import useCloudinary from "../../utils/useCloudinary";
import { createGalleryEntry } from "../../utils/network/Crud";

const ImageUpload = () => {
  const { uploadImage, uploading } = useCloudinary();
  const [images, setImages] = useState([]);
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  const { toggleGlobalLoading, globalLoading } = useTheme();
  const handleImageChange = (e) => {
    if (e.target.files) {
      setImages(Array.from(e.target.files));
    }
  };

  const handleUpload = async () => {
    toggleGlobalLoading(true);
    if (images.length === 0) return;

    const uploadPromises = images.map(async (image) => {
      const result = await uploadImage(image);
      return result; // Return the result instead of updating the state inside
    });

    const imageUrls = await Promise.all(uploadPromises);

    const result = await createGalleryEntry(imageUrls);
    if (result) {
      toggleGlobalLoading(false);
      setImages([]);
      setUploadedImageUrls(imageUrls); // Update the state once, after all uploads are done
      toast.success("Images uploaded successfully", { autoClose: 1200 });
    }
  };

  return (
    <div className={styles.imageUploadContainer}>
      <h2>Upload Images</h2>
      <input
        type="file"
        onChange={handleImageChange}
        multiple
        className={styles.fileInput}
      />
      <button
        disabled={globalLoading || images?.length == 0}
        onClick={handleUpload}
        className={`${styles.uploadBtn} ${
          images?.length == 0 && styles.disabled
        }`}
      >
        {globalLoading ? (
          <ThreeDots
            visible={true}
            height="15"
            width="60"
            color="white"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        ) : (
          "Upload"
        )}
      </button>
      {images.length > 0 && (
        <div>
          {images.map((image, index) => (
            <div key={index} className={styles.previewContainer}>
              <p>{image.name}</p>
            </div>
          ))}
        </div>
      )}
      {uploadedImageUrls.length > 0 && (
        <div className={styles.imagePreview}>
          {uploadedImageUrls.map((url, index) => (
            <img
              loading="lazy"
              key={index}
              src={url}
              alt={`Uploaded ${index}`}
              width="200"
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
