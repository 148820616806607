import { Navigate, Routes } from "react-router-dom";
import MainLayout from "../Components/layout/layout";
import Home from "./Home";
import AllDonations from "./Donation/AllDonations";
import AddDonation from "./Donation/AddDonation";
import AllExpenses from "./Expenses/AllExpenses";
import CreateExpenses from "./Expenses/CreateExpenses";
import EditDonation from "./Donation/EditDonation";
import EditExpenses from "./Expenses/EditExpenses";
import Login from "./Login/Login";
import Gallery from "./Gallery/Gallery";
import { Community } from "./Community/Community";
import AddMember from "./AddMember/AddMember";
export const MainRoutes = () => [
  {
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "*",
        element: <Home />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/donations/addDonation",
        element: <AddDonation collection={"donation"} />,
      },
      {
        path: "/donations/editDonation",
        element: <EditDonation collection={"donation"} />,
      },
      {
        path: "/donations",
        element: <AllDonations collection={"donation"} />,
      },
      {
        path: "/expenses",
        element: <AllExpenses />,
      },
      {
        path: "/expenses/addExpense",
        element: <CreateExpenses />,
      },
      {
        path: "/expenses/editExpense",
        element: <EditExpenses />,
      },
      {
        path: "/gallery",
        element: <Gallery />,
      },
      {
        path: "/community",
        element: <Community />,
      },
      {
        path: "/addMember",
        element: <AddMember />,
      },
    ],
  },
];
