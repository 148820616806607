import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
export async function createDonationEntry(
  collectionName,
  { name, address, amount, date, isPending, description, spendBy, images }
) {
  const currentCollection = collectionName?.collection || collectionName;
  try {
    const payload =
      currentCollection === "donation"
        ? {
            name,
            nameLower: name.toLowerCase(), // Add nameLower for case-insensitive search
            address,
            amount,
            date,
            isPending,
          }
        : { description, spendBy, amount, date, images, isPending };

    const result = await addDoc(collection(db, currentCollection), payload);
    if (result) {
      return { message: "Created Entry Successfully", data: result };
    }
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}
export async function getDonationEntries(
  collectionName,
  searchTerm = "",
  getAll = false,
  filterDate = null // Single date filter
) {
  const currentCollection = collectionName?.collection || collectionName;
  try {
    let baseQuery = collection(db, currentCollection);

    // Apply search filter if searchTerm is provided (partial match)
    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      baseQuery = query(
        baseQuery,
        where("nameLower", ">=", lowerCaseSearchTerm),
        where("nameLower", "<=", lowerCaseSearchTerm + "\uf8ff"),
        orderBy("nameLower") // Required for range queries on nameLower
      );
    }

    // Apply filter for a specific date if provided
    if (filterDate) {
      const startOfDay = new Date(filterDate);
      startOfDay.setHours(0, 0, 0, 0); // Start of the day
      const endOfDay = new Date(filterDate);
      endOfDay.setHours(23, 59, 59, 999); // End of the day

      baseQuery = query(
        baseQuery,
        where("date", ">=", startOfDay),
        where("date", "<=", endOfDay)
      );
    }

    // Apply sorting by date, newest first
    baseQuery = query(baseQuery, orderBy("date", "desc"));

    // Fetch documents
    const querySnapshot = await getDocs(baseQuery);
    const donationList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Get total count of documents
    let totalCount = 0;
    if (!getAll) {
      const totalQuery = query(
        collection(db, currentCollection),
        where("nameLower", ">=", searchTerm.toLowerCase() || ""),
        where("nameLower", "<=", (searchTerm.toLowerCase() || "") + "\uf8ff")
      );
      const totalCountSnapshot = await getCountFromServer(totalQuery);
      totalCount = totalCountSnapshot.data().count;
    } else {
      totalCount = donationList.length;
    }

    return {
      message: "Entries fetched successfully",
      data: donationList,
      totalCount,
    };
  } catch (error) {
    console.error("Error fetching documents:", error);
    return {
      message: "An error occurred",
      data: [],
      totalCount: 0,
    };
  }
}

export async function updateDonation(
  collectionName,
  { name, address, amount, date, isPending, description, spendBy, images = [] },
  id
) {
  const currentCollection = collectionName?.collection || collectionName;
  const payload =
    currentCollection === "donation"
      ? {
          name,
          address,
          amount,
          date,
          isPending,
        }
      : { description, spendBy, amount, date, images, isPending };
  try {
    const todoRef = doc(db, currentCollection, id);

    // Update the document with the new values
    await updateDoc(todoRef, payload);

    // Return a success message
    return {
      message: "Donation Updated Successfully",
      data: { id, name, address, amount, date, isPending },
    };
  } catch (error) {
    console.error("Error updating document: ", error);
    return { message: "Error updating document", error };
  }
}
export async function deleteDonation(collectionName, id) {
  const currentCollection = collectionName?.collection || collectionName;
  try {
    // Create a reference to the document to be deleted
    const todoRef = doc(db, currentCollection, id);

    // Perform the delete operation
    await deleteDoc(todoRef);

    // Since deleteDoc does not return anything on success, we just return a success message
    return {
      message: "Deleted Entry Successfully",
      data: null, // No data to return since deletion is successful
    };
  } catch (error) {
    console.error("Error deleting document: ", error);
    return {
      message: "Error deleting entry",
      data: null,
    };
  }
}
export const getSumFromCollection = async (collectionName, key) => {
  // Validate input types
  if (typeof collectionName !== "string" || typeof key !== "string") {
    throw new Error(
      "Invalid input types. collectionName must be a string, key must be a string."
    );
  }

  try {
    const collectionRef = collection(db, collectionName);
    const querySnapshot = await getDocs(collectionRef);

    // Handle empty collection gracefully
    if (querySnapshot.empty) {
      console.log(`No documents found in the collection: ${collectionName}`);
      return 0;
    }

    // Efficiently calculate sum using reduce
    const totalAmount = querySnapshot.docs.reduce((acc, doc) => {
      const data = doc.data();

      // Ensure the key exists in the document and handle your "isPending" logic
      if (typeof data[key] === "number" && data.isPending !== true) {
        return acc + data[key];
      }
      return acc;
    }, 0);

    return totalAmount;
  } catch (error) {
    console.error(`Error fetching collection '${collectionName}':`, error);
    throw error;
  }
};
export const getTopNFromCollection = async (collectionName, key, topN) => {
  const currentCollection = collectionName;
  try {
    const collectionRef = collection(db, currentCollection);

    // Create a query to filter by 'isPending' being false, order by the 'key', and limit to 'topN'
    const topNQuery = query(
      collectionRef,
      where("isPending", "==", false), // Filter documents where isPending is false
      orderBy(key, "desc"), // Order by the specified key in descending order
      limit(topN) // Limit to top N results
    );

    const querySnapshot = await getDocs(topNQuery);

    // Return an empty array if no documents are found
    if (querySnapshot.empty) {
      console.log(`No documents found in the collection: ${collectionName}`);
      return [];
    }

    // Collect the top N results
    const topEntries = [];
    querySnapshot.forEach((doc) => {
      topEntries.push({ id: doc.id, ...doc.data() });
    });

    return topEntries;
  } catch (error) {
    // Handle errors
    console.error(`Error fetching collection '${collectionName}':`, error);

    // Specific Firestore index error
    if (error.code === "failed-precondition") {
      console.log(
        "Firestore requires a composite index for this query. Please create one in the Firestore console."
      );
    }

    return [];
  }
};

export async function createMember({ designation, image, important, name }) {
  const currentCollection = "members";
  try {
    let index = 0;

    if (important) {
      // Query for documents where 'important' is true
      const importantQuery = query(
        collection(db, currentCollection),
        where("important", "==", true)
      );
      const querySnapshot = await getDocs(importantQuery);
      const importantCount = querySnapshot.size; // Count of 'important' documents

      // Set the index as the number of important documents + 1
      index = importantCount + 1;
    }

    // Prepare the payload, including the index only if 'important' is true
    const payload = {
      designation,
      image,
      important,
      name,
      ...(important && { index }), // Conditionally add 'index' if 'important' is true
    };

    // Add the document to the collection
    const result = await addDoc(collection(db, currentCollection), payload);

    if (result) {
      return { message: "Created Entry Successfully", data: result };
    }
  } catch (e) {
    console.error("Error adding document: ", e);
    throw new Error("Failed to create member entry");
  }
}
export async function getMembers() {
  const currentCollection = "members";

  try {
    // Fetch all documents from the 'members' collection
    const querySnapshot = await getDocs(collection(db, currentCollection));

    // Process the data and extract members
    const members = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return { message: "Fetched Members Successfully", data: members };
  } catch (e) {
    console.error("Error getting documents: ", e);
    return { message: "Error Fetching Members", error: e };
  }
}

export async function createGalleryEntry(images) {
  const currentCollection = "gallery";
  const documentId = "singleGalleryEntry";
  const galleryDocRef = doc(db, currentCollection, documentId);

  try {
    const docSnapshot = await getDoc(galleryDocRef);

    if (docSnapshot.exists()) {
      await updateDoc(galleryDocRef, {
        images: arrayUnion(...images),
      });
      return { message: "Updated Gallery Entry Successfully" };
    } else {
      await setDoc(galleryDocRef, { images });
      return { message: "Created Gallery Entry Successfully" };
    }
  } catch (e) {
    console.error("Error adding or updating document: ", e);
    throw new Error("Failed to create or update gallery entry");
  }
}

export async function getGalleryEntry() {
  const currentCollection = "gallery";
  const documentId = "singleGalleryEntry"; // The same ID used in createGalleryEntry
  const galleryDocRef = doc(db, currentCollection, documentId);

  try {
    const docSnapshot = await getDoc(galleryDocRef);

    if (docSnapshot.exists()) {
      return {
        data: docSnapshot.data(),
        message: "Gallery Entry Retrieved Successfully",
      };
    } else {
      return { data: null, message: "No Gallery Entry Found" };
    }
  } catch (e) {
    console.error("Error retrieving document: ", e);
    throw new Error("Failed to retrieve gallery entry");
  }
}

export async function toggleConfigEntry(configName, state) {
  const currentCollection = "config";
  const documentId = configName;
  const configRef = doc(db, currentCollection, documentId);

  try {
    // Ensure state is a supported data type
    if (
      typeof state !== "string" &&
      typeof state !== "number" &&
      typeof state !== "boolean" &&
      typeof state !== "object"
    ) {
      throw new Error("Unsupported data type for state");
    }

    const docSnapshot = await getDoc(configRef);

    if (docSnapshot.exists()) {
      await updateDoc(configRef, {
        [configName]: state,
      });
      return { message: "Config updated Successfully" };
    } else {
      await setDoc(configRef, { [configName]: state });
      return { message: "Config added Successfully" };
    }
  } catch (e) {
    console.error("Error adding or updating document: ", e);
    throw new Error("Failed to create or update config entry");
  }
}
export async function getAllConfigs() {
  const currentCollection = "config";
  const collectionRef = collection(db, currentCollection);

  try {
    const querySnapshot = await getDocs(collectionRef);
    const configs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    if (configs.length > 0) {
      return {
        data: configs,
        message: "Configs Retrieved Successfully",
      };
    } else {
      return { data: [], message: "No Configs Found" };
    }
  } catch (e) {
    console.error("Error retrieving documents: ", e);
    throw new Error("Failed to retrieve config entries");
  }
}
