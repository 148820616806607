import React from "react";
import AddDonation from "../Donation/AddDonation";
import BackButton from "../../Components/BackButton";

const CreateExpenses = () => {
  return (
    <>
      <BackButton title={"Add Expense"} />
      <AddDonation collection={"expense"} />
    </>
  );
};

export default CreateExpenses;
