import React from "react";
import NavBar from "../navbar/NavBar";
import { Outlet } from "react-router-dom";
import classes from "./Layout.module.scss";
const MainLayout = () => {
  return (
    <section className={classes.container}>
      <NavBar />
      <section className={classes.outlet}>
        <Outlet />
      </section>
    </section>
  );
};

export default MainLayout;
