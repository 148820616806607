import React, { useEffect, useState } from "react";
import styles from "./Community.module.scss"; // SCSS module import
import { getMembers } from "../../utils/network/Crud";
import useTheme from "../../utils/useTheme";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import BackButton from "../../Components/BackButton";

export const Community = () => {
  const [allMembers, setAllMembers] = useState([]);
  const { toggleGlobalLoading, globalLoading } = useTheme();
  const getAllMembersData = async () => {
    try {
      toggleGlobalLoading(true);
      const result = await getMembers();
      if (result) {
        setAllMembers(result?.data);
      }
    } catch (error) {
      toast.error("Something went wrong !", { autoClose: 1200 });
    } finally {
    }
    toggleGlobalLoading(false);
  };

  useEffect(() => {
    getAllMembersData();
  }, []);
  if (globalLoading) {
    return (
      <div className={styles.loaderContainer}>
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="rgb(251,157,71)"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }
  const shuffleArray = (array) => {
    let shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  const shuffledMembers = shuffleArray(
    allMembers?.filter((elem) => !elem?.important) || []
  );

  return (
    <div className={styles["members-page"]}>
      <BackButton title={"Our Community"} />
      {/* CEO and Managers Section */}
      <section className={styles["ceo-managers"]}>
        <div className={styles["member-cards"]}>
          {allMembers
            ?.filter((elem) => elem?.important)
            .sort((a, b) => a?.index - b?.index)
            .map((member, index) => (
              <div
                key={index}
                className={`${styles["member-card"]} ${styles["ceo-manager"]}`}
              >
                <img src={member.image} alt={member.name} />
                <h3>{member.name}</h3>
                <p>{member.designation}</p>
              </div>
            ))}
        </div>
      </section>

      {/* Common People Section */}
      <section className={styles["common-people"]}>
        <div
          className={`${styles["member-cards"]} ${styles["member-card-other-container"]}`}
        >
          {shuffledMembers.map((member, index) => (
            <div
              key={index}
              className={`${styles["member-card"]} ${styles["member-card-other"]}`}
            >
              <img src={member.image} alt={member.name} />
              <h3>{member.name}</h3>
              <p>{member.designation}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Developer Card Section */}
      <section className={styles["developer-section"]}>
        <h2>Developed By</h2>
        <div className={styles["developer-card"]}>
          <img
            src={require("../../assets/profile.webp")} // Developer image
            alt="Developer"
            className={styles["developer-photo"]}
          />
          <div className={styles["developer-info"]}>
            <h3>Prateek Shukla</h3> {/* Developer Name */}{" "}
            <p>Software Engineer L2 at Think Future Technologies</p>{" "}
            <p>
              <b>Email:</b> prateekshukla130@gmail.com
            </p>{" "}
            {/* Developer Email */}
            <p>
              <b>Website:</b>{" "}
              <a
                href="https://portfolio-15a4a.firebaseapp.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles["developer-link"]}
              >
                <b>Click Here</b>
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
