import React, { useState, useEffect, useCallback } from "react";
import { deleteDonation, getDonationEntries } from "../../utils/network/Crud";
import styles from "./AllDonations.module.scss";
import { useNavigate } from "react-router-dom";
import FlatList from "flatlist-react";
import moment from "moment";
import Loader from "../../Components/ProgressBar/Loader";
import { exportToExcel } from "../../utils/helper";
import { FaFileExport, FaRegEye } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import { toast } from "react-toastify";
import ConfirmationModal from "../../Components/ConfirmModal/ConfirmationModal";
import useTheme from "../../utils/useTheme";
import BackButton from "../../Components/BackButton";
import { debounce } from "lodash";
import ImagePreview from "./Component/ImagePreview";

const AllDonations = ({ collection }) => {
  const [donationData, setDonationData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState("");
  const [showPendings, setShowPendings] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    flag: false,
    buferData: "",
  });
  const [isPreviewOpen, setIsPreviewOpen] = useState({
    flag: false,
    buferData: "",
  });
  const navigate = useNavigate();
  const { toggleGlobalLoading, globalLoading, userData } = useTheme();

  // Debounced Fetch Data
  const fetchData = useCallback(async () => {
    try {
      const data = await getDonationEntries(
        collection,
        searchTerm,
        true,
        selectedDate
      );
      if (data) {
        setDonationData(data?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setInitialLoading(false);
    }
  }, [collection, searchTerm, selectedDate, toggleGlobalLoading]);

  const debouncedFetchData = useCallback(
    debounce(() => fetchData(true), 1000),
    [fetchData]
  );
  useEffect(() => {
    debouncedFetchData(false);
  }, [searchTerm, selectedDate]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleEdit = (data) => {
    navigate(
      collection === "expense"
        ? "/expenses/editExpense"
        : "/donations/editDonation",
      { state: data }
    );
  };

  const handleDelete = (id) => {
    setIsModalOpen({
      flag: true,
      buferData: id,
    });
  };

  const handleConfirmDelete = async () => {
    try {
      toggleGlobalLoading(true);
      const result = await deleteDonation(collection, isModalOpen.buferData);
      if (result) {
        toast.success("Deleted Successfully", { autoClose: 1200 });
        debouncedFetchData(); // Refetch data after deletion
      }
    } catch (error) {
      console.error("Error deleting donation:", error);
    } finally {
      toggleGlobalLoading(false);
      setIsModalOpen({ flag: false, buferData: "" });
    }
  };

  const formattedDate = (item) =>
    item?.date?.seconds
      ? moment.unix(item?.date?.seconds).format("DD-MM-YYYY")
      : "No Date";

  const checkPermission = (action, key) => {
    return userData?.permissions?.[key]?.[action] || false;
  };

  const handleView = (item) => {
    setIsPreviewOpen({ flag: true, buferData: item?.images });
  };
  const RenderItem = ({ item }) => {
    return (
      <li key={item.id} className={styles.listItem}>
        <div
          className={item?.isPending ? styles.pendingIndicator : styles.paid}
        >
          {item?.isPending
            ? collection === "expense"
              ? "Payment Pending"
              : "Pending"
            : collection === "expense"
            ? "Payment Done"
            : "Collected"}
        </div>
        <div className={styles.cardContent}>
          <div className={styles.name}>{item?.name}</div>
          {collection !== "expense" ? (
            <span className={styles.address}>
              <span className={styles.date}>
                <b>Address </b> :{" "}
              </span>
              {item?.address?.length > 50
                ? `${item?.address.slice(0, 50)}...`
                : item?.address}
            </span>
          ) : (
            <div className={styles.descriptionBox}>
              <span className={styles.description}>
                <b>Description</b> :{" "}
              </span>
              <span className={styles.description}>
                <span>{item?.description}</span>
              </span>
            </div>
          )}
          {collection === "expense" && (
            <div className={styles.details}>
              <span className={styles.date}>
                <span>
                  <b>Spent By</b> : {item?.spendBy}
                </span>
              </span>
            </div>
          )}
          <div className={styles.details}>
            <span className={styles.date}>
              <span>
                <b>Date </b>:{" "}
              </span>
              {formattedDate(item)}
            </span>
            <span className={styles.amount}>₹{item?.amount}</span>
          </div>
        </div>
        <div className={styles.actionItem}>
          {collection === "expense" && item?.images?.length ? (
            <div
              className={`${styles.btn} ${styles.view}`}
              onClick={() => handleView(item)}
            >
              <FaRegEye />
            </div>
          ) : null}
          {checkPermission("update", "donation") && (
            <div
              className={`${styles.btn} ${styles.edit}`}
              onClick={() => handleEdit(item)}
            >
              <CiEdit />
            </div>
          )}
          {checkPermission("delete", "donation") && (
            <div
              className={`${styles.btn} ${styles.delete}`}
              onClick={() => handleDelete(item?.id)}
            >
              <AiOutlineDelete />
            </div>
          )}
        </div>
      </li>
    );
  };

  const handleExport = async () => {
    toggleGlobalLoading(true);
    try {
      const data = await getDonationEntries(
        collection,
        searchTerm,
        true,
        selectedDate
      );
      if (data) {
        let finalPayload = {};
        if (collection === "expense") {
          finalPayload = data.data.map((elem) => ({
            Description: elem?.description,
            Amount: elem?.amount,
            Spent_By: elem?.spendBy,
            Date: moment.unix(elem?.date?.seconds).format("DD-MM-YYYY"),
          }));
        } else {
          finalPayload = data.data.map((elem) => ({
            Name: elem?.name,
            Amount: elem?.amount,
            Address: elem?.address,
            Date: moment.unix(elem?.date?.seconds).format("DD-MM-YYYY"),
            Pending: elem?.isPending,
          }));
        }
        const totalRow = {
          name: "Total",
          amount: finalPayload.reduce(
            (acc, curr) => (!curr.Pending ? acc + (curr.Amount || 0) : acc),
            0
          ),
        }; // Total row
        exportToExcel(
          finalPayload,
          `${collection === "expense" ? "Expense.xlsx" : "Donations.xlsx"}`,
          totalRow
        );
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      toggleGlobalLoading(false);
    }
  };

  if (initialLoading || globalLoading) return <Loader />;
  const mainData = showPendings
    ? donationData.filter((elem) => elem.isPending)
    : donationData;
  return (
    <>
      <BackButton
        title={collection === "donation" ? "All Donations" : "All Expenses"}
        renderToolbar={
          checkPermission(
            "create",
            collection === "donation" ? "donation" : "expense"
          ) && (
            <button
              className={styles.addBtn}
              onClick={() =>
                navigate(
                  collection === "expense"
                    ? "/expenses/addExpense"
                    : "/donations/addDonation"
                )
              }
            >
              Add New
            </button>
          )
        }
      />
      <div className={styles.container}>
        <div className={`${styles.headerContainer}`}>
          <div className={styles.pendingCont}>
            <input
              value={showPendings}
              onChange={() => setShowPendings(!showPendings)}
              type="checkbox"
            />
            <span>Filter Pending's</span>
          </div>
          <button className={styles.exportBtn} onClick={handleExport}>
            Export <FaFileExport className={styles.icon} />
          </button>
        </div>
        <div className={styles.inputContainer}>
          <input
            className={styles.searchInput}
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <input
            className={styles.dateInput}
            type="date"
            value={selectedDate || ""}
            onChange={handleDateChange}
          />
        </div>
        {mainData?.length ? (
          mainData?.map((elem) => <RenderItem item={elem} />)
        ) : (
          <>
            {!initialLoading && (
              <div className={styles.emptyList}>List is empty!</div>
            )}
          </>
        )}
        <ConfirmationModal
          isOpen={isModalOpen.flag}
          onConfirm={handleConfirmDelete}
          onCancel={() => setIsModalOpen({ flag: false, buferData: "" })}
        />
        <ImagePreview
          isOpen={isPreviewOpen?.flag}
          images={isPreviewOpen?.buferData}
          onClose={() => setIsPreviewOpen({ bufferData: "", flag: false })}
        />
      </div>
    </>
  );
};

export default AllDonations;
