import React from "react";
import { useForm } from "react-hook-form";
import styles from "./AddMember.module.scss";
import useCloudinary from "../../utils/useCloudinary";
import { createMember } from "../../utils/network/Crud";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const AddMember = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const { uploadImage, uploading, error } = useCloudinary();
  const onSubmit = async (data) => {
    if (data?.image) {
      const result = await uploadImage(data?.image[0]);
      if (result) {
        handleCreateMember(result);
      }
    }
  };
  const navigate = useNavigate();
  const handleCreateMember = async (image) => {
    try {
      const result = await createMember({
        ...getValues(),
        important: getValues().important === "false" ? false : true,
        image: image,
      });
      if (result) {
        toast.success("Member added successFully");
        navigate(-1);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            {...register("name", { required: "Name is required" })}
          />
          {errors.name && <p className={styles.error}>{errors.name.message}</p>}
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="designation">Designation</label>
          <input
            type="text"
            id="designation"
            {...register("designation", {
              required: "Designation is required",
            })}
          />
          {errors.designation && (
            <p className={styles.error}>{errors.designation.message}</p>
          )}
        </div>

        <div className={styles.formGroup}>
          <label>Important Member</label>
          <div className={styles.radioGroup}>
            <label>
              <input
                type="radio"
                value={true}
                {...register("important", { required: "Select an option" })}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                value={false}
                {...register("important", { required: "Select an option" })}
              />
              No
            </label>
          </div>
          {errors.important && (
            <p className={styles.error}>{errors.important.message}</p>
          )}
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="image">Profile Image</label>
          <input
            type="file"
            id="image"
            accept="image/*"
            {...register("image", { required: "Image is required" })}
          />
          {errors.image && (
            <p className={styles.error}>{errors.image.message}</p>
          )}
          {uploading && <p>Uploading image...</p>}
          {error && (
            <p className={styles.error}>
              Error uploading image: {error.message}
            </p>
          )}
        </div>

        <button type="submit" className={styles.submitButton}>
          Add Member
        </button>
      </form>
    </div>
  );
};

export default AddMember;
