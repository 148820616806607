// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAgxoKi5BTynYvt8A3QdIZhF0I9hoOIIeE",
  authDomain: "navdurga-samiti-godhar.firebaseapp.com",
  projectId: "navdurga-samiti-godhar",
  storageBucket: "navdurga-samiti-godhar.appspot.com",
  messagingSenderId: "513695479753",
  appId: "1:513695479753:web:a0ab08262c15ca6b339a18",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);
const storage = getStorage(app);
export { db, storage };
