import { createContext, useState } from "react";
const defaultValue = ["dark", "light"];
export const ThemeContext = createContext({
  mode: defaultValue,
  isDarkMode: true | false,
  toggleTheme: () => {},
  toggleLangauge: () => {},
  isLanguageEnglish: true | false,
  globalLoading: true | false,
  toggleGlobalLoading: () => {},
  updateUserData: () => {},
  userData: {},
  configs: {},
  setConfigs: () => {},
});

export const ThemeProvider = ({ children }) => {
  const getCurrentTheme = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isEnglish, setIsEnglish] = useState(false);
  const [configs, setConfigs] = useState({});
  const [globalLoading, setGlobalLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const value = {
    mode: isDarkMode ? "dark" : "light",
    isDarkMode,
    isLanguageEnglish: isEnglish,
    toggleTheme: () => setIsDarkMode((mode) => !mode),
    toggleLangauge: () => setIsEnglish((language) => !language),
    globalLoading,
    toggleGlobalLoading: (state) => setGlobalLoading(state),
    updateUserData: (paylaod) => setUserData(paylaod),
    userData,
    configs,
    setConfigs,
  };
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};
