import { ToastContainer } from "react-toastify";
import Pages from "./Pages";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "./utils/context";
import useTheme from "./utils/useTheme";
import { useEffect } from "react";
import Loader from "./Components/ProgressBar/Loader";
import { getAllConfigs } from "./utils/network/Crud";

function App() {
  const {
    updateUserData,
    toggleGlobalLoading,
    globalLoading,
    configs,
    setConfigs,
  } = useTheme();
  const getConfigData = async () => {
    const data = await getAllConfigs();
    if (data) {
      setConfigs({
        ...configs,
        showLive: data?.data[1]?.showLive,
        channelId: data?.data[0]?.channelId,
      });
    }
  };
  const validateLogin = () => {
    getConfigData();
    toggleGlobalLoading(true);
    try {
      const loginData = localStorage.getItem("Db456");
      if (loginData) {
        const parsedData = JSON.parse(loginData);
        updateUserData(parsedData);
      }
    } catch (error) {
      console.error("Error reading login data:", error);
    } finally {
      toggleGlobalLoading(false);
    }
  };

  useEffect(() => {
    validateLogin();
  }, []);
  if (globalLoading) <Loader />;
  return (
    <>
      <ToastContainer />
      <Pages />
    </>
  );
}

export default App;
