import React, { useEffect, useState } from "react";
import styles from "./Gallery.module.scss";
import ImageUpload from "../../Components/ImageUpload/UploadImage";
import useTheme from "../../utils/useTheme";
import { getGalleryEntry } from "../../utils/network/Crud";
import BackButton from "../../Components/BackButton";

const Gallery = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const { userData } = useTheme();
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const galleryEntry = await getGalleryEntry();
        if (galleryEntry.data) {
          setImageUrls(galleryEntry.data?.images);
        } else {
          console.log(galleryEntry.message);
        }
      } catch (error) {
        console.error("Error fetching gallery: ", error.message);
      }
    };

    fetchImages();
  }, []);

  return (
    <div>
      <BackButton title={"Gallery"} />
      {userData?.permissions?.uploadImage?.write && <ImageUpload />}
      <div className={styles.galleryContainer}>
        {!!imageUrls?.length ? (
          imageUrls?.reverse()?.map((url, index) => (
            <div key={index} className={styles.imageCard}>
              <img src={url} alt={`Uploaded ${index}`} />
            </div>
          ))
        ) : (
          <div className={styles.imageCard}>
            <span>No Images Found...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
