export const Auth_Users = [
  {
    type: "superAdmin",
    name: "Prateek",
    email: "prateekshukla130@gmail.com",
    password: "Prateek@120966",
    permissions: {
      donation: { read: true, update: true, delete: true, create: true },
      expense: { read: true, update: true, delete: true, create: true },
      uploadImage: { write: true },
      addMember: { write: true },
      startLive: { write: true },
    },
  },
  {
    type: "admin",
    name: "Surendra",
    email: "surendragodhar@gmail.com",
    password: "Surendra_936",
    permissions: {
      donation: { read: true, update: true, delete: true, create: true },
      expense: { read: true, update: true, delete: true, create: true },
      uploadImage: { write: true },
      addMember: { write: true },
    },
  },
  {
    type: "admin",
    name: "Anshu",
    email: "anshugodhar@gmail.com",
    password: "Anshu_863",
    permissions: {
      donation: { read: true, update: false, delete: false, create: true },
      expense: { read: true, update: false, delete: false, create: true },
      uploadImage: { write: false },
      addMember: { write: false },
    },
  },
  {
    type: "admin",
    name: "Sujal",
    email: "sujalgodhar@gmail.com",
    password: "Sujal_936",
    permissions: {
      donation: { read: true, update: false, delete: false, create: true },
      expense: { read: true, update: false, delete: false, create: true },
      uploadImage: { write: false },
      addMember: { write: false },
    },
  },
];
