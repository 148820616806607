import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const BackButton = ({ title, renderToolbar }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        marginTop: 15,
        marginRight: 5,
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IoIosArrowBack
          style={{ marginRight: 7, zIndex: 9, fontSize: 25 }}
          onClick={() => navigate(-1)}
        />
        <span style={{ fontWeight: "600" }}>{title}</span>
      </div>
      <div>{renderToolbar}</div>
    </div>
  );
};

export default BackButton;
