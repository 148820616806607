import React, { useState } from "react";
import styles from "./ImagePreview.module.scss";

const ImagePreview = ({ images, isOpen, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  return (
    <>
      {isOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={onClose}>
              &times;
            </button>
            <div className={styles.imageContainer}>
              <img
                loading="lazy"
                src={images[currentImageIndex]}
                alt="Preview"
              />
            </div>
            {images?.length > 1 && (
              <div className={styles.navigation}>
                <button onClick={handlePrev} disabled={currentImageIndex === 0}>
                  Prev
                </button>
                <button
                  onClick={handleNext}
                  disabled={currentImageIndex === images.length - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ImagePreview;
