import React, { useEffect, useState } from "react";
import styles from "./Home.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { FcMoneyTransfer } from "react-icons/fc";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { GrGallery, GrMoney } from "react-icons/gr";
import {
  getSumFromCollection,
  getTopNFromCollection,
} from "../utils/network/Crud";
import useTheme from "../utils/useTheme";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { GiExpense } from "react-icons/gi";
import { LiaDonateSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import { TbBuildingCommunity } from "react-icons/tb";
import { BsRecord2Fill } from "react-icons/bs";
import LiveStream from "./LiveStream";
const Home = () => {
  const [homeData, setHomeData] = useState({
    totalDonation: "",
    totalExpenses: "",
    topDonars: [],
  });
  const { toggleGlobalLoading, globalLoading, configs } = useTheme();
  const amountLeft = homeData?.totalDonation - homeData?.totalExpenses;
  const events = [
    {
      src: "https://www.omspiritualshop.com/cdn/shop/articles/38_1_1024x1024.jpg?v=1721902234",
      alt: "Coming Soon",
    },
    {
      src: "https://res.cloudinary.com/drnml80um/image/upload/v1728075730/ifitxgx4d0r79hv53ch8.jpg",
      alt: "Event 3",
    },
    {
      src: "https://res.cloudinary.com/drnml80um/image/upload/v1728075730/xrzwcitqm7742ayxvdqh.jpg",
      alt: "Event 3",
    },
    {
      src: "https://res.cloudinary.com/drnml80um/image/upload/v1727954072/z9utksjpcab8mlcokeqe.jpg",
      alt: "Event 3",
    },
    {
      src: "https://res.cloudinary.com/drnml80um/image/upload/v1728075745/xpmg1shujczu5yphvatg.jpg",
      alt: "Event 5",
    },
    {
      src: require("../assets/img_1.jpg"),
      alt: "Event 2",
    },
    {
      src: require("../assets/img_2.jpg"),
      alt: "Event 4",
    },
    // Add more images as needed
  ];
  const getHomeData = async () => {
    toggleGlobalLoading(true);
    try {
      const donationsSum = await getSumFromCollection("donation", "amount");
      const expensesSum = await getSumFromCollection("expense", "amount");
      const top10Donations = await getTopNFromCollection(
        "donation",
        "amount",
        20
      );
      if (donationsSum) {
        setHomeData({
          ...homeData,
          totalDonation: donationsSum,
          totalExpenses: expensesSum,
          topDonars: top10Donations,
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong", { autoClose: 1200 });
    } finally {
      toggleGlobalLoading(false);
    }
  };
  useEffect(() => {
    getHomeData();
  }, []);
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <section className={styles.mainTop}>
        <div className={styles.itemCard}>
          <div
            className={styles.logoCard}
            onClick={() => navigate("donations")}
          >
            <LiaDonateSolid className={styles.logo} />
          </div>
          <span className={styles.itemTitle}>Daanpeti</span>
        </div>
        <div className={styles.itemCard}>
          <div className={styles.logoCard} onClick={() => navigate("expenses")}>
            <GiExpense className={styles.logo} />
          </div>
          <span className={styles.itemTitle}>Expense</span>
        </div>
        <div className={styles.itemCard}>
          <div className={styles.logoCard} onClick={() => navigate("gallery")}>
            <GrGallery className={styles.logo} />
          </div>
          <span className={styles.itemTitle}>Gallery</span>
        </div>
        <div className={styles.itemCard}>
          <div
            className={styles.logoCard}
            onClick={() => navigate("community")}
          >
            <TbBuildingCommunity className={styles.logo} />
          </div>
          <span className={styles.itemTitle}>Community</span>
        </div>
      </section>
      <section className={styles.eventsSection}>
        <Carousel
          className={styles.Carousel}
          autoPlay
          infiniteLoop
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          transitionTime={500}
          interval={5000}
        >
          {events.map((event, index) => (
            <div className={styles.carouselItem} key={index}>
              <img src={event.src} alt={event.alt} />
            </div>
          ))}
        </Carousel>
      </section>
      {configs?.showLive && (
        <section className={styles.eventsSection}>
          <span style={{ fontWeight: "600" }}>
            <span>Live </span>
            <BsRecord2Fill color="red" />
          </span>
          <LiveStream channelId={configs?.channelId} />
        </section>
      )}
      {globalLoading ? (
        <div className={styles.loaderContainer}>
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="rgb(251,157,71)"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <section className={styles.summarySection}>
            <div className={`${styles.card} ${styles.donarCard}`}>
              <FcMoneyTransfer className={styles.icon} />
              <h2>Total Donations</h2>
              <p>₹ {homeData?.totalDonation?.toLocaleString() || 0}</p>
            </div>
            <div className={styles.card}>
              <FaMoneyBillTransfer className={styles.icon} />
              <h2>Total Expenses</h2>
              <p>₹ {homeData?.totalExpenses?.toLocaleString() || 0}</p>
            </div>
          </section>

          <section className={styles.amountLeftSection}>
            <GrMoney className={styles.icon} />
            <h2>Amount Left</h2>
            <p>₹ {amountLeft?.toLocaleString() || 0}</p>
          </section>
          <section className={styles.eventSection}>
            <img src={require("../assets/event-1.png")} />
            <img src={require("../assets/event-2.png")} />
          </section>
          <section className={styles.donorsSection}>
            <h2>🌟 Top 20 Donors</h2>
            <ul>
              {homeData?.topDonars?.map((donor, index) => (
                <li key={index}>
                  <span>
                    {index + 1}. {donor?.name}
                  </span>
                  <strong>₹ {donor?.amount?.toLocaleString()}</strong>
                </li>
              ))}
            </ul>
          </section>
        </>
      )}
    </div>
  );
};
export default Home;
