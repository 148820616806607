import React from "react";
import EditDonation from "../Donation/EditDonation";
import BackButton from "../../Components/BackButton";

const EditExpenses = () => {
  return (
    <>
      <BackButton title={"Edit Expense"} />
      <EditDonation collection={"expense"} />
    </>
  );
};

export default EditExpenses;
