import React from "react";
import styles from "./ConfirmationModal.module.scss"; // Ensure the correct path

const ConfirmationModal = ({ isOpen, onClose, onConfirm, text, icon }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <span className={styles.modalIcon}>{icon}</span>
          <h2>Confirmation</h2>
        </div>
        <div className={styles.modalBody}>
          <p>{text}</p>
        </div>
        <div className={styles.modalFooter}>
          <button className={styles.cancelButton} onClick={onClose}>
            Cancel
          </button>
          <button className={styles.confirmButton} onClick={onConfirm}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
