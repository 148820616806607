import React, { useEffect } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { MainRoutes } from "./routes";
import classes from "./Page.module.scss";
import useTheme from "../utils/useTheme";

const Pages = () => {
  const { isDarkMode, userData = {} } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  // Check permission based on action (create, update) and resource (donation, expense)
  const checkPermission = (action, key) => {
    if (!userData?.permissions) {
      return false;
    }
    return userData.permissions[key]?.[action] || false;
  };

  // Validate routes based on permissions
  const validateRoutes = (path) => {
    switch (path) {
      case "/addDonation":
        return checkPermission("create", "donation");
      case "/editDonation":
        return checkPermission("update", "donation");
      case "/addExpense":
        return checkPermission("create", "expense");
      case "/editExpense":
        return checkPermission("update", "expense");
      case "/login":
        return !userData?.isLoggedIn;
      default:
        return true; // Return true for other routes or handle as needed
    }
  };

  // Handle route validation when the path changes
  useEffect(() => {
    const isValid = validateRoutes(location.pathname);
    if (!isValid) {
      navigate("/"); // Redirect if the user doesn't have permission
    }
  }, [location, userData, navigate]);

  return (
    <>
      <div className={`${!isDarkMode && classes.pageLight} ${classes.page}`}>
        {useRoutes(MainRoutes())}
      </div>
      <div className={classes.container}>
        <header className={classes.header}>
          <h1>Mobile Only Website</h1>
        </header>
        <main className={classes.mainContent}>
          <p>This website is designed for mobile devices only.</p>
        </main>
        <footer className={classes.footer}>
          <p>
            &copy; 2024 Bharat Mata Navdurga Utsav Samiti Godhar Rewa (M.P.)
          </p>
        </footer>
      </div>
    </>
  );
};

export default Pages;
